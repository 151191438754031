import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container } from '@components/global';

const YourCoach = () => (
  <StaticQuery
    query={graphql`{
  art_fast: file(sourceInstanceName: {}, name: {eq: "fast"}) {
    childImageSharp {
      gatsbyImageData(width: 760, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  art_learn: file(sourceInstanceName: {}, name: {eq: "learn_yourself"}) {
    childImageSharp {
      gatsbyImageData(width: 760, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  art_ideas: file(sourceInstanceName: {}, name: {eq: "ideas"}) {
    childImageSharp {
      gatsbyImageData(width: 760, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`}
    render={data => (
      <Section id="YourCoach">
        <Container>
          <Grid>
            <div>
              <h2>Your coach</h2>
              <p>
                Savleen has studied Psychology, Criminology, completed
                her Masters in Social Work and is a member of the Australian
                Association of Social Workers. She later trained in Integrative
                Couple Therapy with Relationships Australia to enhance her
                practice in Relationship Therapy. Savleen previously worked with
                people grappling with Mental Health issues, Trauma and Family
                Violence and constantly strives to bring a holistic and systemic
                approach to her practice. Within her Counselling work at
                Community Health, she developed a program to coach parents build
                stronger relationships with their children by understanding and
                responding to their behaviour and needs.
              </p>
            </div>
            <Art>
              <GatsbyImage image={data.art_fast.childImageSharp.gatsbyImageData} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <GatsbyImage image={data.art_learn.childImageSharp.gatsbyImageData} />
            </Art>
            <div>
              <p>
                As a child migrant originally from India, Savleen brings to her
                practice, a deep insight into issues surrounding cultural
                awareness, attachment, isolation and depression in both adults
                and children alike. As a survivor of abuse in its many forms,
                Savleen has applied her values to guide and raise herself. Her
                early life traumatic experiences have shaped her understanding
                of the importance of healthy relationships to survive and
                thrive.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <p>
                Savleen lives by her values of empowerment, self-efficacy and
                equality. With resilience, she has taken back control of her
                life and now applies the learning from her past challenges as a
                tool to understand and help others. Her personal history, along
                with her education and training has enabled her to develop the
                passion and skills necessary to support individuals, parents,
                couples and families to form meaningful relationships with each
                other and themselves.
                <br />
              </p>
            </div>
            <Art>
              <GatsbyImage image={data.art_ideas.childImageSharp.gatsbyImageData} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
    color: #ad2b2b;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default YourCoach;
