import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import YourCoach from '@sections/YourCoach';
import CoachingAreas from '@sections/CoachingAreas';
import Bookings from '@sections/Bookings';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <YourCoach />
    <CoachingAreas />
    <Bookings />
    <Footer />
  </Layout>
);

export default IndexPage;
