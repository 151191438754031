import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Section, Container } from '@components/global';

const Bookings = () => (
  <StaticQuery
    query={graphql`{
  allFile(filter: {sourceInstanceName: {eq: "team"}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          gatsbyImageData(width: 400, height: 400, layout: CONSTRAINED)
        }
      }
    }
  }
}
`}
    render={data => (
      <Section id="Bookings" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <H2>Bookings</H2>
          <div>
            <p>Get Set Thrive is an inclusive, safe and confidential space.</p>
            <p>
              {' '}
              Clients must be 18+ years of age or accompained by a legal guardian.{' '}
            </p>
            <p>Free consult for new clients only.</p>
          </div>
          <FreeSession>
            <p>
              <SignUpButton>
                <a href="https://forms.gle/ar7jrngVNjeCTZ3F8" class="button">
                  Book now!
                </a>
              </SignUpButton>
            </p>
          </FreeSession>
          <div>
            {/*  <PriceGrid>
              <PriceUl>
                <PriceHeader>1 Session *</PriceHeader>
                <PriceListItem>30 mins planning</PriceListItem>
                <PriceListItem>60 mins coaching</PriceListItem>
                <PriceListItem>15 min follow-up</PriceListItem>
                 <SignUpButton>
                  <a href="#" class="button">
                    Book now
                  </a>
                </SignUpButton>
              </PriceUl>
            </PriceGrid>
            <PriceGrid>
              <PriceUl>
                <PriceHeader>3 Sessions</PriceHeader>
                <PriceListItem>45 mins planning</PriceListItem>
                <PriceListItem>60 mins coaching</PriceListItem>
                <PriceListItem>15 mins follow-up</PriceListItem>
                  <SignUpButtonImp>
                  <a href="#" class="button">
                    Book now
                  </a>
                </SignUpButtonImp> 
              </PriceUl>
            </PriceGrid> */}
          </div>
        </Container>
      </Section>
    )}
  />
);

/* const PriceGrid = styled.div`
  float: left;
  width: 50%;
  padding: 8px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`;

const PriceUl = styled.ul`
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PriceHeader = styled.li`
  background-color: #eee;
  color: black;
  font-size: 24px;
  padding: 16px;
`;

const PriceListItem = styled.li`
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
`; */

const FreeSession = styled.div`
  border: 2px solid #ccc;
  background-color: #eee;
  border-radius: 5px;
  padding: 16px;
  margin: 16px 0;
  text-align: center;
`;

const SignUpButton = styled.div`
  font-size: 24px;
  padding: 16px;
  a {
    text-decoration: none;
    background-color: #ad2b2b;
    border: none;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    color: white !important;
  }
`;
/* const SignUpButtonImp = styled.li`
  background-color: #d2d0d0;
  font-size: 24px;
  padding: 16px;
  a {
    text-decoration: none;
    background-color: #4caf50;
    border: none;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    color: ${props => props.theme.color.black.regular};
  }
`; */

const H2 = styled.h2`
   {
    margin-bottom: 16px;
    color: #ad2b2b;
    padding-top: 16px;
  }
`;

export default Bookings;
