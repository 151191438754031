import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Header = () => (
  <StaticQuery
    query={graphql`{
  art_build: file(
    sourceInstanceName: {}
    name: {eq: "get_set_thive_logo_trimmed"}
  ) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Grid>
            <Art>
              <GatsbyImage image={data.art_build.childImageSharp.gatsbyImageData} />
            </Art>
            <Text>
              <h1>
                {` Parenting & Relationship`}
                <br />
                {`Coaching`}
              </h1>
              <br />
              <br />
              <span>Are you having issues in your relationship?</span>
              <br />
              <span>Are you finding modern parenting challenging?</span>
              <br />
              <br />
              <Hightlight>
              <SignUpButton>
                <a href="https://forms.gle/ar7jrngVNjeCTZ3F8" class="button">
                  Book now!
                </a>
              </SignUpButton>
                {` or call `}
                <ExternalLink href="tel:+61478989477">
                  <Phone> 0478 989 477</Phone>
                </ExternalLink>{' '}
                for a FREE consult!
              </Hightlight>
              <br /> <br />
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);
const SignUpButton = styled.div`
  font-size: 24px;
  padding: 16px;
  a {
    text-decoration: none;
    background-color: #ad2b2b;
    border: none;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    color: white !important;
  }
`;
const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 96px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Hightlight = styled.span`
  color: #ad2b2b;
`;

const Art = styled.figure`
  justify-self: center;
  width: 15%;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    width: 30%;
  }
`;

const Phone = styled.div`
  font-size: 22px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: 8px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-rows: 1fr;
    grid-gap: 8px;
    align-items: center;

    > ${Art} {
      order: 0;
    }
  }
`;

const Text = styled.div`
  justify-self: center;
  text-align: center;
  font-size: 18px;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default Header;
