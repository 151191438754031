import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container } from '@components/global';

const CoachingAreas = () => (
  <StaticQuery
    query={graphql`{
  art_learn: file(sourceInstanceName: {}, name: {eq: "parent-child"}) {
    childImageSharp {
      gatsbyImageData(width: 760, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  art_ideas: file(sourceInstanceName: {}, name: {eq: "relationship"}) {
    childImageSharp {
      gatsbyImageData(width: 760, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`}
    render={data => (
      <Section id="CoachingAreas" accent>
        <Container>
          <div>
            <H2>Coaching areas</H2>
            <p>
              Savleen’s therapeutic coaching style is person-centered,
              strength-based, solution-focused, culturally informed and
              values-oriented. While she applies The Gottman Method as a couple
              therapist and approaches client issues with an attachment and
              relational lens, she does not believe in imposing set modalities
              to all clients. Rather, she assesses each client’s experience
              individually and offers evidence-based strategies that will best
              allow them to create desired changes.
            </p>
          </div>

          <Grid inverse>
            <Art>
              <GatsbyImage image={data.art_learn.childImageSharp.gatsbyImageData} alt="" />
            </Art>
            <div>
              <h3>Parenting </h3>
              <br />
              <p>
                The world is changing at a faster pace than ever and each
                generation brings with it a new set of challenges. Modern
                parenting requires parents to update their skills in order to
                meet their child’s needs for development. There’s no shame in
                feeling lost or stuck, as a parent. Welcoming support and
                guidance is the best thing you can do for yourself and your
                child.
              </p>
              <br />
              <br />
              <p>Here are some ways we can help you:</p>
              <ul>
                <ResizeList>
                  Understand and respond to your child’s needs and behaviours.
                </ResizeList>
                <ResizeList>
                  Foster independence, confidence and build resilience in your
                  child.
                </ResizeList>
                <ResizeList>
                  Set appropriate boundaries and guidance Strengthen connection
                  and bond with your child.
                </ResizeList>
              </ul>
            </div>
          </Grid>
          <Grid>
            <div>
              <h3>Relationships</h3>
              <br />
              <p>
                Relationships are our primary connection to the world. They
                influence how we view, feel and act in the world. So whether
                it’s our partner, family or friends, maintaining strong,
                positive relationships is essential to our wellbeing and it
                takes effort to nurture them.
              </p>
              <br />
              <br />
              <div>
                <p>
                  Here are some ways we can help you to enhance the quality of
                  your relationships:
                </p>

                <ul>
                  <ResizeList>
                    {' '}
                    Renew hope and rediscover passion with your partner.
                  </ResizeList>
                  <ResizeList>
                    Gain perspective on a sensitive issue impacting on your
                    relationship.
                  </ResizeList>
                  <ResizeList>
                    Break unhealthy patterns you get stuck in.
                  </ResizeList>
                  <ResizeList>
                    Manage conflict in a constructive manner, with improved
                    communication skills and awareness.
                  </ResizeList>
                  <ResizeList>
                    Build personal strength, self-esteem and boundaries to
                    protect yourself from unhealthy relationships.
                  </ResizeList>
                </ul>
              </div>
              <br />
            </div>
            <Art>
              <GatsbyImage image={data.art_ideas.childImageSharp.gatsbyImageData} alt="" />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const H2 = styled.h2`
   {
    margin-bottom: 16px;
    color: #ad2b2b;
  }
`;

const ResizeList = styled.li`
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
    color: #ad2b2b;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default CoachingAreas;
